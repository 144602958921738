
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {Box, Flex ,Text} from "../primitives";
import Image from "next/image";
import Link from "next/link";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import Icon1 from "../../public/index/98aebdfa-heading-btm.webp"
import Icon2 from "../../public/index/08308b86-heading-shape-left.webp"
import card1 from  "../../public/index/8484b07c-img-8.webp"
import card2 from  "../../public/index/6b7362f4-img-6.webp"
import card2_1 from  "../../public/index/7b2032f3-img-2.webp"
import card3 from  "../../public/index/b90740e8-img-5.webp"
import card3_1 from  "../../public/index/dd58b424-img-4.webp"
import card4 from  "../../public/index/1c97e1e4-img-7.webp"
import card4_1 from  "../../public/index/2c84604d-img-1.webp"
import card5 from  "../../public/index/e700ebba-img-3.webp"
import shapeLeft from "../../public/index/bb686632-heading-shape-1.png"
import shapeRight from "../../public/index/a1165ffc-heading-shape-2.png"
import AvatarIcon from  "../../public/index/60cf70c3-team-11.webp"
import AvatarIcon1 from  "../../public/index/2cb1323c-team-77.webp"
import AvatarIcon2 from  "../../public/index/49e19a6c-team-88.webp"
import AvatarIcon3 from  "../../public/index/f7b801ea-team-44.webp"
import AvatarIcon4 from  "../../public/index/cee455e7-team-66.webp"
import AvatarIcon5 from  "../../public/index/9f5e8509-team-22.webp"
import AvatarIcon6 from  "../../public/index/7ffb14ba-team-5.webp"
import AvatarIcon7 from  "../../public/index/ddaa11f6-team99.webp"
type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')

    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }
    return (
        <Box  >
            <Box css={{
                backgroundImage: `url(${Icon2.src})`,
                backgroundPosition: "top left",
                backgroundRepeat: "no-repeat",
                maxWidth:"60%",
                margin:"0 auto"
            }}>
                <Box css={{
                    backgroundImage: `url(${Icon1.src})`,
                    backgroundPosition: "bottom right",
                    backgroundRepeat: "no-repeat",

                }}>
                    <Flex align="center" justify="center" direction="column">
                        <Text style="h2" css={{
                            letterSpacing: "2rem",
                            marginTop:"$6",
                            padding:"0 $4",
                            "@md": {
                                letterSpacing: ".5rem",
                                marginTop:"$6",
                            }
                        }}>
                            Explore, Collect And Trade Rare
                            <Text style="h2" css={{
                                letterSpacing: ".5rem",
                                marginTop:"$6",
                                padding:"0 $4",
                                color:"#53C343",
                                "@md": {
                                    letterSpacing: ".5rem",
                                    marginTop:"$6",
                                    color:"#53C343",
                                }
                            }}>
                                NFT’s
                            </Text>
                        </Text>


                    </Flex>
                </Box>

            </Box>

            <Flex align="center" justify="center" css={{
                marginTop:"$6"
            }}>
                <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`} style={{
                    backgroundImage: "linear-gradient(180deg, #99E54D 0%, #53C343 100%)",
                    height:"100px",
                    borderRadius:" 15px",
                }}>
                            <span style={{
                                color: "#ffffff",
                                padding: "20px 36px 20px 36px",
                                lineHeight:"100px",
                                textAlign:"center",
                                fontSize:"30px"
                            }}>Browse NFTs</span>
                </Link>
            </Flex>

            <Flex justify="between" css={{
                marginTop:'$6'
            }}>

            </Flex>
           <Box css={{
               animation:"scale-down 1.25s ease-out forwards",
               marginTop:'$4'
           }}>
             <Flex align="center" justify="center" css={{
                 marginTop:"$6",
                 gap: 24,
                 width:"100%",
                 flexDirection:"column",
                 "@md": {
                     marginTop:"$6",
                     flexDirection:"row"
                 }
             }}>
                 <Box css={{

                     "@md":{
                         width:"19%",
                     }

                 }}>
                     <Box css={{
                         borderRadius: "30px",
                         border:"4px solid #3E4249",
                         cursor: "pointer",
                         "&:hover":{
                                 border:"4px solid #99E54D",
                         }
                         // "@hover": {
                         //     borderRadius: "30px",
                         //     border:"4px solid #99E54D",
                         // }


                     }}>
                         <Image src={card1} alt='' />
                     </Box>

                 </Box>
                 <Box css={{

                     "@md":{
                         width:"19%"
                     }

                 }}>
                     <Box css={{
                         borderRadius: "300px",
                         border:"4px solid #3E4249",
                         cursor: "pointer",
                         "&:hover":{
                             border:"4px solid #99E54D",
                         }
                     }}>
                         <Image src={card2} alt='' />
                     </Box>

                     <Box  css={{
                         borderRadius: "120px",
                         border:"4px solid #3E4249",
                         cursor: "pointer",
                         marginTop:"$6",
                         "&:hover":{
                             border:"4px solid #99E54D",
                         }
                     }}>
                         <Image src={card2_1} alt=''  style={{
                             borderRadius: "120px",
                         }}/>
                     </Box>
                 </Box>
                 <Box css={{
                     "@md":{
                         width:"19%"
                     }

                 }}>
                    <Box css={{
                        borderRadius: "30px",
                        border:"4px solid #3E4249",
                        cursor: "pointer",
                        marginTop:"100%",
                        "&:hover":{
                            border:"4px solid #99E54D",
                        }
                    }}>
                        <Image src={card3} alt='' />
                    </Box>
                     <Box css={{
                         marginTop:"$6",
                         borderRadius: "120px",
                         border:"4px solid #3E4249",
                         cursor: "pointer",
                         "&:hover":{
                             border:"4px solid #99E54D",
                         }
                     }}>
                         <Image src={card3_1} alt='' /></Box>
                     </Box>

                 <Box css={{
                     width:"100%",
                     "@md":{
                         width:"19%"
                     }

                 }}>
                     <Box css={{
                         borderRadius: "120px",
                         border:"4px solid #3E4249",
                         cursor: "pointer",
                         "&:hover":{
                             border:"4px solid #99E54D",
                         }
                     }}>
                         <Image src={card4} alt='' />
                     </Box>
                    <Box css={{
                        marginTop:"$6",
                        borderRadius: "120px",
                        border:"4px solid #3E4249",
                        cursor: "pointer",
                        "&:hover":{
                            border:"4px solid #99E54D",
                        }

                    }}>
                        <Image src={card4_1} alt='' style={{
                            borderRadius: "120px",
                        }}/>
                    </Box>

                 </Box>
                 <Box css={{
                     width:"100%",
                     "@md":{
                         width:"19%"
                     }

                 }}>
                     <Box css={{
                         borderRadius: "30px",
                         border:"4px solid #3E4249",
                         cursor: "pointer",
                         "&:hover":{
                             border:"4px solid #99E54D",
                         }
                     }}>
                         <Image src={card5} alt='' />
                     </Box>

                 </Box>
             </Flex>
           </Box>
            <Flex align="center" justify="between" direction="column" css={{
                marginTop:"$6",
                "@md": {
                    marginTop:"$6",
                    alignItems:"center",
                    justifyContent:"space-between",
                    flexDirection:"row"
                }
            }}>

            </Flex>
                <Box css={{
                    backgroundImage: `url(${shapeLeft.src})`,
                    backgroundPosition: "top left",
                    backgroundRepeat: "no-repeat",
                    maxWidth:"60%",
                    margin:"0 auto"
                }}>
                    <Box css={{
                        backgroundImage: `url(${shapeRight.src})`,
                        backgroundPosition: "top right",
                        backgroundRepeat: "no-repeat",

                    }}>
                        <Flex align="center" justify="center" direction="column">
                            <Text style="h2" css={{
                                letterSpacing: "2rem",
                                padding:"0 $4",
                                "@md": {
                                    letterSpacing: ".5rem",
                                }
                            }}>
                                Most Popular Artists
                            </Text>


                        </Flex>
                    </Box>

                </Box>
            <Flex align="center" wrap="wrap" css={{
                justifyContent:"space-around"
            }}>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex  align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>David Clark</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>3.905 Eth</Text>
                        </Box>
                    </Flex>

                </Box>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon1} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Paul McCaul</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>3.805 Eth</Text>
                        </Box>
                    </Flex>
                </Box>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon2} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Angela White</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.712 Eth</Text>
                        </Box>
                    </Flex>
                </Box>


                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon1} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Adem Parker</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.905 Eth</Text>
                        </Box>
                    </Flex>

                </Box>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon3} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Adem Parker</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.905 Eth</Text>
                        </Box>
                    </Flex>
                </Box>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon4} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Adem Parker</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.905 Eth</Text>
                        </Box>
                    </Flex>
                </Box>

                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon5} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Adem Parker</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.905 Eth</Text>
                        </Box>
                    </Flex>

                </Box>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center"  css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon6} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Adem Parker</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.905 Eth</Text>
                        </Box>
                    </Flex>
                </Box>
                <Box css={{
                    padding: "25px 25px 25px 25px",
                    backgroundColor: "#1F2228",
                    transition: "background 0.5s",
                    border:"1px solid #3E4249",
                    borderRadius: "120px 120px 120px 120px",
                    width:"30%",
                    marginTop:"$6"
                }}>
                    <Flex align="center" css={{
                        justifyContent:"space-around"
                    }}>
                        <Image src={AvatarIcon7} alt=""  style={{
                            borderRadius:"50%",
                            backgroundColor:"#414B61"
                        }}/>
                        <Box>
                            <Text style="h3" css={{
                                color:"#fff",
                                fontSize:"18px",
                                display:"block"
                            }}>Adem Parker</Text>
                            <Text style="h3" css={{
                                color:"#9BABCD",
                                fontSize:"18px"
                            }}>2.905 Eth</Text>
                        </Box>
                    </Flex>
                </Box>
            </Flex>


            <Box css={{
                background:"linear-gradient(90deg, #00ff38, #2056df 49.48%, #d74848 99.48%)",
                width:"100%",
                height:"2px",
                marginTop:'$6'
            }}>

            </Box>
        </Box>
    )
}

type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({ params, res }) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
    // init BaseApi
    // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
    // DefaultChain
    // console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'volume',
        }

    const trendingCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        trendingCollectionsQuery,
        headers
    )
    let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'sales',
        }

    const featuredCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        featuredCollectionQuery,
        headers
    )

    let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            type: 'any',
        }

    const trendingMintsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending-mints/v1`,
        trendingMintsQuery,
        headers
    )

    const promises = await Promise.allSettled([
        trendingCollectionsPromise,
        featuredCollectionsPromise,
        trendingMintsPromise,
    ]).catch((e) => {
        console.error(e)
    })

    const trendingCollections: Props['ssr']['trendingCollections'] =
        promises?.[0].status === 'fulfilled' && promises[0].value.data
            ? (promises[0].value.data as Props['ssr']['trendingCollections'])
            : {}
    const featuredCollections: Props['ssr']['featuredCollections'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['featuredCollections'])
            : {}

    const trendingMints: Props['ssr']['trendingMints'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['trendingMints'])
            : {}

    res.setHeader(
        'Cache-Control',
        'public, s-maxage=120, stale-while-revalidate=180'
    )
    return {
        props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
    }
}
export default NFTBanner